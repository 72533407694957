import { Row, Col } from "antd";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy_page">
      <Row className="container" align="center">
        <h1 className="title_page">POLÍTICA DE PRIVACIDADE</h1>
        <Col span={24}>
          <h2 className="title_privacy">1. INTRODUÇÃO</h2>
          <p>
            A proteção do direito à reserva da intimidade da vida privada, e em concreto do direito à proteção dos dados pessoais, é uma das prioridades da ZAMBON PRODUTOS
            FARMACÊUTICOS, LTDA. (adiante, ZAMBON).
          </p>
          <p>
            A ZAMBON está inteiramente comprometida com o cumprimento do Regulamento Geral de Proteção de Dados da União Europeia e com a legislação relativa à proteção de dados
            pessoais vigente, sendo o seu cumprimento um objetivo prioritário para a ZAMBON.
          </p>
          <p>
            O objetivo desta política é explicar como a ZAMBON procede ao tratamento dos seus dados pessoais, que podem ser recolhidos através dos diferentes formulários que se
            encontram disponíveis no nosso website para essa finalidade. Os seus dados pessoais serão tratados pela ZAMBON de forma confidencial e apenas para as finalidades para
            as quais tem fundamento legal para o fazer, conforme explicado nesta política.
          </p>

          <h2 className="title_privacy">2. FINALIDADES DO TRATAMENTO</h2>
          <p>
            O responsável pelo tratamento dos seus dados pessoais é ZAMBON con domicilio social en Rua Comandante Enrique Maya, 1, 1500-192 Lisboa (Portugal) con CIF 502 155 930.
          </p>
          <p>A ZAMBON recolhe os seus dados pessoais para as seguintes finalidades, nos websites em que estas opções se encontrem disponíveis:</p>
          <ul>
            <li>
              <b style={{ color: "#AEBA00" }}>Contactos:</b> Se o utilizador contacta a ZAMBON, através dos nossos canais, os seus dados serão tratados para dar resposta às dúvidas
              e questões levantadas.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Registo:</b> Os seus dados serão tratados para gerir o seu registo e conceder acesso a informação, recursos e conteúdos exclusivamente
              reservados a profissionais de saúde.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Acesso a webinars:</b> Os seus dados são tratados para efeitos de acesso, gestão do registo e manutenção e controlo da sua
              participação nos webinars da ZAMBON em que se inscreveu.
            </li>
          </ul>

          <p>
            Inscrição na newsletter: Se o utilizador autorizar expressamente a receção de Newsletters, os seus dados serão tratados para a gestão e para o controlo do envio e da
            receção da newsletter.
          </p>

          <ul>
            <li>
              <b style={{ color: "#AEBA00" }}>Comunicações comerciais:</b> Se o utilizador autorizar expressamente a receção de informações sobre determinados produtos ou serviços,
              os seus dados serão tratados para efeitos de gestão e controlo do envio e da receção de tal informação.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Acesso à sua área reservada:</b> Facilitar o acesso à área reservada do Website da ZAMBON, onde o utilizador se pode registar, a
              qualquer momento, nos Webinars que a Zambon disponibiliza.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Perguntas ou consultas:</b> Dar respostas às questões e sugestões realizadas pelos utilizadores com as diferentes ferramentas que a
              ZAMBON coloca à disposição do Utilizador, nas páginas da web onde essa opção seja fornecida.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Gestão da sua opinião:</b> Gerir a sua opinião realizada através das ferramentas que a ZAMBON coloca à disposição do utilizador (chat,
              ou outras ferramentas semelhantes), bem como para analisar a mesma, nas páginas da web onde essa opção seja fornecida.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Notificações:</b> Notificá-lo com confirmações de inscrição, lembretes e compromissos do calendário dos Webinars em que se registou,
              por correio eletrónico e/ou por SMS, caso assim tenha autorizado (o utilizador pode, a qualquer momento, cancelar o envio de notificações através da secção “Minhas
              notificações”).
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Outras finalidades:</b> Qualquer outra finalidade, que aqui não se encontre identificada, será comunicada ao utilizador antes de se
              proceder ao tratamento dos seus dados pessoais.
            </li>
          </ul>

          <p>
            Quanto aos dados dos Profissionais de Saúde: A ZAMBON pode tratar os dados pessoais dos profissionais de saúde, que tenha obtido diretamente junto dos seus titulares ou
            recebido através da IQVIA Information, S.A, para os seguintes fins:
          </p>

          <ul>
            <li>
              <b style={{ color: "#AEBA00" }}>Contacto:</b> para poder contactá-los;
            </li>
          </ul>

          <p>Informações de contacto e dados de visita médica: para gerir e obter informações sobre visitas médicas;</p>

          <ul>
            <li>
              <b style={{ color: "#AEBA00" }}>Submissão de comunicações:</b> Caso tenhamos obtido o seu consentimento, usamos os dados dos profissionais de saúde para enviar
              comunicações administrativas relacionadas com outros serviços, presença em conferências e envio de documentos relacionados com transferências de valores.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Convidá-lo para eventos organizados / patrocinados pela ZAMBON:</b> Caso tenhamos obtido o consentimento do profissional de saúde,
              podemos convidá-lo para eventos organizados ou patrocinados pela ZAMBON. Neste caso e de acordo com a legislação aplicável, bem como com os padrões deontológicos a
              que nos encontramos obrigados a cumprir enquanto associada da APIFARMA (Associação Portuguesa da Indústria Farmacêutica), serão solicitados dados adicionais.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Categorias de dados disponíveis:</b>
            </li>
            <ul>
              <li>Dados de identificação: nome e sobrenomes; e,</li>
              <li>Dados profissionais e académicos: especialidade, subespecialidades, centro de saúde onde presta serviços.</li>
            </ul>
          </ul>

          <p>
            A legitimidade deste tratamento baseia-se no nosso interesse legítimo Informamos ainda que, em certos casos, pode ser necessário o tratamento dos seus dados pessoais
            para cumprimos obrigações legais ou obrigações contratuais.
          </p>

          <p>Em todos os outros casos e sempre que seja adequado, solicitaremos o seu consentimento para tratar os seus dados pessoais.</p>

          <h2 className="title_privacy">3. ENVIO DE COMUNICAÇÕES COMERCIAIS</h2>
          <p>
            Tendo sempre como fundamento o seu consentimento, enviamos comunicações comerciais com informações sobre os nossos produtos, atividades e serviços personalizados, de
            acordo com os seus gostos e preferências.
          </p>

          <p>
            Informamos que, a qualquer momento e em cada uma das comunicações, poderá revogar o seu consentimento para o envio de futuras comunicações comerciais, selecionando a
            opção “sair” ou comunicando a sua vontade de revogar o seu consentimento através dos seguintes endereços:
          </p>
          <p>
            (i).Correio eletrónico que deverá enviar para <a href="mailto:privacyze@zambongroup.com">privacyze@zambongroup.com</a>
          </p>
          <p>(ii).Correio postal enviado para Polígono Industrial Can Bernardes-Subirà C/ Maresme, 5 08130 Santa Perpètua de la Mogoda (Barcelona)</p>

          <h2 className="title_privacy">4. COMUNICAÇÕES COM BASE NO SEU PERFIL</h2>

          <p>
            As comunicações enviadas pela ZAMBON podem ser segmentadas de acordo com o seu perfil, tendo em conta os seus gostos e preferências, se tivermos obtido o seu
            consentimento ou nos termos autorizados pela lei, informando-o devidamente em cada caso.
          </p>
          <p>
            Os seus dados não serão vendidos ou disponibilizados por qualquer outro meio a terceiros. Em determinadas situações, poderá ser fornecido o acesso a entidades que
            prestem serviços à ZAMBON, sempre de acordo com as finalidades por esta definidas.
          </p>

          <h2 className="title_privacy">5. PRAZO DE CONSERVAÇÃO</h2>

          <p>
            Os seus dados serão conservados pelo período estabelecido nos campos de recolha de dados no website, exceto se consentir à sua conservação por um período superior, e
            sem prejuízo da sua conservação e manutenção por um prazo mais extenso, para o cumprimento de obrigações legais.
          </p>

          <ul>
            <li>
              <b style={{ color: "#AEBA00" }}>Contacto:</b> Conservamos os seus dados pessoais até que o seu contacto seja resolvido.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Registo:</b> Conservamos os seus dados pessoais até que desative a sua conta.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Inscrição em newsletter:</b> Conservamos os seus dados pessoais até que cancele a sua subscrição.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Comunicações comerciais:</b> Conservamos os seus dados pessoais até que revogue o seu consentimento.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Acesso a webinars:</b> Conservamos os seus dados pessoais durante o tempo necessário para cumprir com as finalidades previstas.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Acesso à sua área reservada:</b> Conservamos os seus dados pessoais durante o tempo necessário para cumprir com as finalidades
              previstas.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Perguntas ou consultas:</b> Conservaremos os seus dados pessoais até resolvermos a sua consulta.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Gestão da sua opinião:</b> Conservaremos os seus dados pessoais durante 1 ano.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Notificações:</b> Conservamos os seus dados pessoais durante 1 ano.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Outras finalidades:</b> Conservamos os seus dados pessoais durante o tempo necessário para cumprir com as finalidades previstas,
              informando-o previamente ao tratamento dos seus dados.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Dados do IQVIA:</b> Conservamos enquanto o profissional de saúde não se opuser ao tratamento e não revogar o consentimento dado para
              finalidades adicionais.
            </li>
          </ul>

          <p>
            No caso dos seus dados serem tratados para a execução de um contrato, eles serão conservados pelo período de execução do contrato e até à prescrição do direito para a
            apresentação de ação judicial.
          </p>

          <h2 className="title_privacy">6. EXERCÍCIO DE DIREITOS E RECLAMAÇÕES PERANTE A CNPD OU A AEPD</h2>
          <p>Em consequência do tratamento dos seus dados pessoais por parte da ZAMBON, a legislação aplicável confere-lhe um conjunto de direitos.</p>
          <p>Em concreto, pode exercer os seguintes direitos:</p>

          <ul>
            <li>
              <b style={{ color: "#AEBA00" }}>Direito de acesso:</b> tem o direito a aceder aos seus dados pessoais que são objeto de tratamento por parte da ZAMBON e as
              finalidades para que são tratados.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Direito de retificação:</b> tem o direito a retificar qualquer informação que lhe diga respeito que esteja inexata ou incompleta.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Direito de apagamento:</b> poderá solicitar que os seus dados pessoais sejam eliminados dos nossos ficheiros.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Direito de oposição:</b> poderá opor-se ao tratamento dos seus dados para o envio de ações de marketing direto, bem como por motivos
              relacionados com a sua situação particular, a menos que o responsável apresente razões imperiosas e legítimas para o seu tratamento.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Direito de limitação do tratamento:</b> poderá solicitar a limitação do tratamento dos seus dados nos casos em que a sua exatidão seja
              contestada, se o tratamento for ilícito e se tenha oposto ao apagamento dos seus dados pessoais, solicitando alternativamente a limitação da sua utilização ou quando
              os seus dados já não forem necessários para o tratamento, mas precisar dos mesmos para o exercício ou defesa de um direito.
            </li>
            <li>
              <b style={{ color: "#AEBA00" }}>Direito de portabilidade dos seus dados:</b> sempre que tecnicamente possível e no caso de o tratamento se basear no seu consentimento
              ou num contrato assinado com o responsável, tem o direito de poder solicitar que os seus dados pessoais sejam transmitidos a outro responsável pelo tratamento.
            </li>
            <li>
              De acordo com o disposto na Lei º 41/2004, de 18 de Agosto, poderá revogar a qualquer momento o seu consentimento ao envio de comunicações comerciais por meios
              eletrónicos.
            </li>
            <li>Revogar o consentimento prestado para o tratamento dos seus dados pessoais.</li>
          </ul>

          <p>
            O exercício dos direitos pode ser realizado dirigindo-se ao nosso comité de proteção de dados através de um dos seguintes meios (podendo ser exigida prova da sua
            identificação, caso não tenhamos registo do seu e-mail ou morada):
          </p>
          <p>
            (i). Mediante mensagem de correio eletrónico que deverá ser enviada para o endereço <a href="mailto:privacyze@zambongroup.com">privacyze@zambongroup.com</a>
          </p>
          <p>
            (ii). Mediante correio postal enviado para Polígono Industrial Can Bernardes-Subirà C/ Maresme, 5 08130 Santa Perpètua de la Mogoda (Barcelona), com assinatura
            reconhecida.
          </p>

          <p>
            Para além disso se não desejar exercer um direito específico, mas pretende realizar uma questão ou sugestão sobre o tratamento dos seus dados pessoais, poderá
            dirigir-se aos endereços fornecidos para efeitos do exercício dos seus direitos.
          </p>

          <p>
            Por outro lado, a ZAMBON informa-o que tem o direito de apresentar uma reclamação perante a Comissão Nacional de Proteção de Dados (CNPD) ou perante a Agencia Española
            de Protección de Datos (AEPD), autoridades de controlo principais em matéria de proteção de dados.
          </p>

          <h2 className="title_privacy">7. CONFIDENCIALIDADE E SEGURANÇA NO TRATAMENTO DOS DADOS</h2>
          <p>A informação recebida pela ZAMBON através do presente Website será tratada com a máxima reserva e confidencialidade.</p>
          <p>
            Da mesma forma, a ZAMBON declara e garante que estabeleceu todos os meios técnicos e organizativos à sua disposição para evitar a perda, o uso indevido, a alteração, o
            acesso não autorizado e o furto de dados fornecidos pelos utilizadores. Não obstante, informamos que as medidas de segurança na Internet não são inexpugnáveis.
          </p>

          <h2 className="title_privacy">8. TRANSFERÊNCIAS INTERNACIONAIS</h2>
          <p>
            Caso haja necessidade de proceder ao tratamento dos seus dados pessoais fora do Espaço Económico Europeu (EEE), a ZAMBON garante que aplicará todas as medidas para
            assegurar e proteger os seus dados pessoais. As principais medidas adotadas pela ZAMBON ao realizar uma transferência internacional de dados pessoais são a assinatura
            de cláusulas contratuais tipo aprovadas pela Comissão Europeia, o estabelecimento de normas vinculativas aplicáveis às empresas, a estipulação de vinculações e
            certificações ou códigos de conduta aprovados pela Comissão Europeia.
          </p>

          <h2 className="title_privacy">9. ENTRE EM CONTACTO COM A ZAMBON</h2>
          <p>Caso apresente alguma questão ou dúvida sobre o tratamento dos seus dados pessoais, por favor, entre em contato connosco através dos seguintes meios:</p>
          <p>
            (i). Mensagem de correio eletrónico que deverá enviar para o endereço <a href="mailto:privacyze@zambongroup.com">privacyze@zambongroup.com</a>
          </p>
          <p>(ii). Formulários de contacto no Website</p>
          <p>(iii). Correio postal enviado para Polígono Industrial Can Bernardes-Subirà C/ Maresme, 5 08130 Santa Perpètua de la Mogoda (Barcelona)</p>
        </Col>
      </Row>
    </div>
  );
}

export default PrivacyPolicy;
