import { Layout, Menu } from "antd";
import { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { DashboardOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import axios from "axios";
import Lottie from "lottie-react";

import loadingLogoAnimation from "../../animations/data.json";
import config from "../../utils/server";

import "./MainAdmin.css";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const items = [
  getItem("DASHBOARD", "dashboard", <DashboardOutlined />),
  getItem("INSCRITOS", "subscribers", <UserOutlined />),
  getItem("FINALISTAS", "finalists", <UserOutlined />),
  getItem("ADMINISTRADORES", "users", <UserOutlined />),
  getItem("LOGOUT", "logout", <LogoutOutlined />),
];

const MainAdmin = ({ handleLogout }) => {
  const location = useLocation();
  let pathname = location.pathname.split("/");

  const [current, setCurrent] = useState(pathname[pathname.length - 1]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    let tokenData = localStorage.getItem("token");
    if (tokenData) {
      axios
        .post(`${config.server_ip}/auth/verifyToken`, {
          data: tokenData,
        })
        .then((res) => {
          console.log(res);
          setTimeout(() => {
            setIsLoading(false);
          }, 2000);
        })
        .catch((err) => {
          console.log(err);
          return navigate("/login");
        });
    } else {
      return navigate("/login");
    }
  }, []);

  function handleClickMenu(e) {
    let value;
    if (e.key === "logout") {
      handleLogout();
      navigate("/");
    } else {
      navigate(e.key);
    }
  }

  return (
    <Layout className="admin_layout">
      <div className={`loading_page ${!isLoading ? "hidden" : ""}`}>
        <Lottie animationData={loadingLogoAnimation} />
      </div>
      <Sider width={250} collapsible collapsed={collapsed} onCollapse={(value) => setCollapsed(value)}>
        <div className="logo" onClick={() => navigate("/")}></div>
        <Menu theme="dark" defaultSelectedKeys={[current]} mode="inline" items={items} onClick={handleClickMenu} />
      </Sider>
      <Content className="admin_layout_content">
        <Outlet />
      </Content>
    </Layout>
  );
};
export default MainAdmin;
