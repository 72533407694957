import { Row, Col } from "antd";
import "./CookiesPolicy.css";

function CookiesPolicy() {
  return (
    <div className="cookies_page">
      <Row className="container" align="center">
        <h1 className="title_page">POLÍTICA DE COOKIES</h1>
        <Col span={24}>
          <h2 className="title_cookies">1. O que é uma Cookie?</h2>
          <p className="mb-0">
            Uma Cookie é um pequeno ficheiro de computador, um marcador, que é armazenado e lido no dispositivo do Utilizador, computador e/ou telemóvel por exemplo, quando
            consulta o Website, lê um e-mail, instala ou usa um software ou aplicação para o telemóvel, independentemente do dispositivo utilizado (computador, smartphone, tablet,
            etc.). Nesta política, a ZAMBON refere-se a todos os marcadores armazenados e lidos no computador e/ou dispositivo móvel como “Cookies”.
          </p>
          <p className="mt-0">
            Os Utilizadores são informados de que quando navegam no Website as informações podem ser armazenadas no seu browser ou recuperados do mesmo, geralmente sob a forma de
            cookies. Estas informações podem relacionar-se com o Utilizador, com o tipo de browser utilizado, com as suas preferências de navegação, com o Site (páginas
            visualizadas, data e hora de login, etc.) ou com o seu terminal (computador, tablet, smartphone, etc.), e é utilizado principalmente para garantir que o Website está a
            funcionar corretamente.
          </p>

          <h2 className="title_cookies">2. Informação importante sobre o consentimento</h2>
          <p className="mb-0">
            O armazenamento ou a leitura de certas Cookies não exige o prévio consentimento do Utilizador, seja porque não requer o tratamento de dados pessoais doUtilizador, seja
            porque são estritamente necessários para a prestação do serviço requerido pelo Utilizador.
          </p>
          <p className="m-0">O armazenamento ou a reprodução de Cookies que não sejam as supramencionadas no parágrafo anterior exige o consentimento prévio do Utilizador.</p>
          <p className="m-0">
            Os cookies não permitem que a ZAMBON identifique pessoalmente os Utilizadores, mas para recolher informações de uma forma geral quando visitam o Website,
            permitindo-lhes ter uma experiência personalizada.
          </p>
          <p className="m-0">
            A ZAMBON compromete-se a não comunicar o conteúdo destes cookies a terceiros, a não ser que tenham a obrigação de o fazer, conforme ordenado por autoridade judicial ou
            administrativa. Quando inicia sessão no Site, o Utilizador é expressamente convidado a aceitar a utilização de cookies presentes no Website.
          </p>
          <p className="m-0">
            O Utilizador pode, a qualquer altura, opor-se ao armazenamento ou reprodução destas Cookies utilizadas pela ZAMBON, seja através da sua eliminação do dispositivo, ou
            por modificar as definições do browser. O Utilizador pode bloquear a utilização de todos ou de alguns dos cookies ou eliminar cookies previamente instalados no seu
            navegador.
          </p>
          <p className="mt-0">
            Bloquear certos tipos de cookies ou apagá-los pode afetar o acesso a determinadas funções ou páginas do Website ou impossibilitar, ou tornar inacessíveis, determinados
            serviços oferecidos no Website, pelos quais a ZAMBON não pode ser responsabilizada.
          </p>

          <h2 className="title_cookies">2. Informação importante sobre o consentimento</h2>
          <p>Utilizamos os cookies cujos propósitos são descritos abaixo.</p>
          <ul>
            <li>Cookies analíticos</li>
          </ul>
          <p>
            Estes são cookies que nos informam sobre o uso e desempenho do nosso website e permitem-nos elaborar estatísticas sobre volumes de tráfego e utilização dos vários
            elementos do nosso website (conteúdos visitados e caminhos de visitante), permitindo-nos tornar os nossos serviços mais interessantes e fáceis de utilizar (páginas ou
            secções mais frequentemente consultadas, a maioria dos artigos de leitura, etc.).
          </p>

          <h2 className="title_cookies">4. Cookies de Terceiros</h2>
          <p className="mb-0">
            As funcionalidades deste Website podem utilizar serviços fornecidos por terceiros, por exemplo reprodução de vídeos ou outros conteúdos difundidos noutras páginas e em
            redes sociais.
          </p>
          <p className="m-0">
            Se der o seu consentimento, estes terceiros podem armazenar cookies que permitirão a visualização do conteúdo hospedado por estes terceiros diretamente no endereço do
            website deles ou partilhar os nossos conteúdos.
          </p>
          <p className="mt-0">
            Através destes cookies, estes terceiros podem recolher e utilizar os seus dados de navegação para os seus próprios fins, de acordo com a sua política de
            confidencialidade.
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default CookiesPolicy;
