import { Row, Col, Button } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import pdfRegulation from "../../../assets/docs/240531-Zambon-CCCN-Regulamento-v1.pdf";

import "./Regulation.css";

function Regulation() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <div className="regulation_page">
      <Row className="container" align="center">
        <h1 className="title_page">REGULAMENTO</h1>
        <Col span={24}>
          <h2 className="title_regulation">Regras de apresentação</h2>
          <ol type="a">
            <li className="text-left">Os casos clínicos deverão versar sobre Doenças de Movimento ou Patologia Neuromuscular e ser redigidos e apresentados em português.</li>
            <li className="text-left">
              Os concorrentes devem enviar um documento em formato .doc ou .pdf com a descrição do caso clínico e, posteriormente, os 5 finalistas, selecionados pelo Comité de
              Peritos, devem enviar a apresentação do caso.
            </li>
            <li className="text-left">
              No caso clínico apresentado pelo Participante não deverão constar dados pessoais (nome do autor, local de trabalho, entre outros), ou seja, o documento deverá ser
              completamente anónimo, permitindo assim uma apreciação objetiva e imparcial.
            </li>
            <li className="text-left">Não serão admitidos a Concurso Casos Clínicos que contenham dados pessoais que possam levar à identificação do doente.</li>
            <li className="text-left">Não serão admitidos ao Concurso os Casos Clínicos que contenham dados que não sejam manifestamente precisos ou estejam incompletos.</li>
            <li className="text-left">
              Se algum caso contiver a descrição de um acontecimento adverso, o autor deverá comunicá-lo no portal{" "}
              <Link to="https://www.infarmed.pt/web/infarmed/submissaoram" target="_blank">
                RAM
              </Link>
            </li>
          </ol>

          <h2 className="title_regulation">Primeira fase de avaliação</h2>
          <ul>
            <li className="text-left">
              Cada Participante deverá preencher o formulário disponível na página da Secretaria Técnica do Concurso de Casos Clínicos: ZFORMACOESNEURO.PT com os seus dados (nome,
              e-mail, telefone, local de trabalho, número da Ordem dos Médicos/Cartão de Cidadão) e o consentimento informado e realizar o upload do ficheiro do caso clínico em
              formato descritivo (.pdf).
            </li>
            <li className="text-left">O caso clínico deverá ser apresentado num documento descritivo estruturado com as seguintes secções:</li>
            <ul>
              <li className="text-left">Título do caso clínico </li>
              <li className="text-left">Dados do doente (raça, idade, sexo) </li>
              <li className="text-left">História clínica</li>
              <li className="text-left">Exploração/exame neurológico </li>
              <li className="text-left">Diagnóstico diferencial, exames </li>
              <li className="text-left">Tratamento </li>
              <li className="text-left">Evolução </li>
              <li className="text-left">Discussão </li>
              <li className="text-left">Conclusões </li>
              <li className="text-left">Bibliografia (máximo de 7 referências, numeradas no texto e em ordem) </li>
              <li className="text-left">Anexos: máximo de 3 tabelas e/ou 3 figuras</li>
            </ul>
            <li className="text-left">A descrição do caso clínico não deve exceder as 1500 palavras.</li>
            <li className="text-left">A apresentação dos casos clínicos deverá ser feita em formato descritivo (.pdf).</li>
          </ul>

          <h2 className="title_regulation">Segunda fase de avaliação</h2>
          <ul>
            <li className="text-left">
              Cada finalista deverá preencher o formulário disponível na página da Secretaria Técnica do Concurso de Casos Clínicos: ZFORMACOESNEURO.PT com os seus dados (nome,
              e-mail, telefone, local de trabalho, número da Ordem dos Médicos/Cartão de Cidadão) e o consentimento informado e realizar o upload do ficheiro do caso clínico em
              formato apresentação (.ppt).
            </li>
            <li className="text-left ">
              Durante o curso “Comunicação médica e apresentação gráfica”, trabalharemos na apresentação do caso que os finalistas terão de enviar como trabalho final do curso, no
              início de abril.
            </li>
            <li className="text-left">O caso deverá ser apresentado em formato PowerPoint (.ppt).</li>
          </ul>

          <Row gutter={[24]} className="button_regulation_div">
            <Col xs={24} sm={24} md={12} lg={12} xl={12} align={width < 900 ? "center" : "right"}>
              <Button icon={<CloudDownloadOutlined />} className="primary_button_regulation" onClick={() => window.open(pdfRegulation)}>
                REGULAMENTO COMPLETO
              </Button>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} align={width < 900 ? "center" : "left"}>
              <Link to="/inscricao">
                <Button className="second_button_regulation">PARTICIPAR</Button>
              </Link>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <p className="text-center f-12">G4874 - junho'2024</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Regulation;
